// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    'aws_project_region': 'eu-west-1',
    'aws_cognito_identity_pool_id': 'eu-west-1:e6c4f8c2-2786-4daa-ba87-6936216148b6',
    'aws_cognito_region': 'eu-west-1',
    'aws_user_pools_id': 'eu-west-1_win9mlkJ7',
    'aws_user_pools_web_client_id': '701nu9kj8vtdka6uhh95k7fc7c',
    'oauth': {}
};


export default awsmobile;